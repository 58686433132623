(function($) {

  var $btns = $('.btn-filter').click(function() {

    var items  = $('.project-filter').children('li');

    if (this.id === 'all') {
      $('.projects-overview > article').fadeIn(450);
    } else {
      var $el = $('.' + this.id).fadeIn(450);
      $('.projects-overview > article').not($el).hide();
    }
    $btns.removeClass('active');
    $(this).addClass('active');

    items.removeClass('active');
    $(this).parent().addClass('active');
  });

})(jQuery);
